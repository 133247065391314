import React, { FC, memo, useState, useEffect } from 'react';
import SBModal from 'common/components/Modal/SBModal';
import { CardSchema, PaymentModalType, QuickFlowStates } from '../types';
import styled from 'styled-components';
import Typography from 'common/components/Typography/Typography';
import theme from 'theme/theme';
import Spacer from 'common/components/Spacer/Spacer';
import restService from 'services/rest.service';
import Card from 'common/icons/Card.icon';
import { useStoreActions } from 'store/store';
import { useViewport } from 'use-viewport';
import Cryptocurrency from 'common/icons/Cryptocurrency.icon';
import MetamaskClient from 'services/web3.client';
import { isBrowserSupported } from 'utils/browser-check';

import geoService from 'services/geo.service';
import { useAppDispatch, useAppSelector } from 'hooks/reduxToolkit';
import { setModalType, setShowState } from 'store-persist/reducers/modalSlice';
// import RemainingBits from '../RemainingBits';
import { getTopPosition } from 'utils/functions';

interface PaymentModalProps {
  isOpen: boolean;
  type: PaymentModalType;
  header?: JSX.Element;
  artist?: string;
  song?: string;
  costOfSelected?: string;
  selectedBits?: number;
  onClose: () => void;
  //onNoAvailableCards: () => void;
  onWalletConnected: () => void;
  onNoAvailableAddresses: () => void;
  onMetaMaskNotInstalled: () => void;
  onBrowserNotSupported: () => void;
  onUSACountryDetected: () => void;
  purchaseExpired: () => void;
  next: ({
    state,
    payload,
  }: {
    state: QuickFlowStates;
    payload?: CardSchema;
  }) => void;
}

const AddFundsOptions: FC<PaymentModalProps> = ({
  isOpen,
  type,
  header,
  artist,
  song,
  costOfSelected,
  selectedBits,
  onClose,
  //onNoAvailableCards,
  onWalletConnected,
  onNoAvailableAddresses,
  onMetaMaskNotInstalled,
  onBrowserNotSupported,
  onUSACountryDetected,
  purchaseExpired,
  next,
}) => {
  const viewport = useViewport();

  const dispatch = useAppDispatch();
  const { modalType, isReloaded } = useAppSelector((state) => state.modalSlice);

  const { expiryTime } = useAppSelector((state) => state.modalSlice);

  let metamaskClient: MetamaskClient;

  if (artist && song) {
    metamaskClient = new MetamaskClient(
      process.env.REACT_APP_METAMASK_SWITCH_CHAIN_NETWORK_ID,
      `/${artist.toLowerCase().replace(' ', '-')}/${song
        .toLowerCase()
        .replace(' ', '-')}/bits?state=restore`,
    );
  } else {
    metamaskClient = new MetamaskClient(
      process.env.REACT_APP_METAMASK_SWITCH_CHAIN_NETWORK_ID,
    );
  }

  const setGlobalBanner = useStoreActions(
    (actions) => actions.globalbanner.setGlobalBanner,
  );

  const setNumberOfBits = useStoreActions(
    (actions) => actions.purchase.setNumberOfBits,
  );

  const setCostOfSelected = useStoreActions(
    (actions) => actions.purchase.setCostOfSelected,
  );

  const [usersCountry, setUsersCountry] = useState<string>('');

  useEffect(() => {
    if (isOpen) {
      (window as any).gtag("event", "purchase_option", {});
    }
  }, [isOpen])

  useEffect(() => {
    geoService.getUserIP().then((userIP) => {
      if (userIP)
        geoService.getUserCountry(userIP).then((countryResponse) => {
          if (countryResponse) {
            setUsersCountry(countryResponse.country);
          }
        });
    });
  }, []);

  useEffect(() => {
    if (expiryTime > 0 && expiryTime < new Date().getTime()) {
      purchaseExpired();
    } else if (isReloaded) {
      if (modalType === 'CardAuto') {
        usingCardHandler();
      }

      if (modalType === 'USADetection') {
        usingCryptoHandler();
      }

      if (modalType === 'AddCryptoFunds') {
        usingCryptoHandler();
      }
    }
  }, []);

  useEffect(() => {
    if (expiryTime > 0 && expiryTime < new Date().getTime()) {
      purchaseExpired();
    } else if (isOpen) {
      dispatch(setModalType('AddFundsOptions'));
      dispatch(setShowState(isOpen));
    }
  }, [isOpen]);

  function usingCardHandler() {
    next({
      state: QuickFlowStates.CardAuto
    });
    /*
    restService
      .getCardsList()
      .then((res) => {
        const numCards = res.length;

        if (numCards > 0) {
          // Show card dropdown
          const card = res[0];

          const cardData = {
            cardId: card.cardid,
            nickname: card.nickname,
            last4: card.data.last4,
            verification_type: card.verification_type,
          };

          next({
            state: QuickFlowStates.CardAuto,
            payload: cardData,
          });
        } else {
          // Pop add card modal
          onNoAvailableCards();
        }
      })
      .catch((error) => {
        setGlobalBanner({
          title: 'Failed to retrieve cards:',
          text: error.message,
        });
      });*/
  }

  function usingCryptoHandler() {
    // check if in US
    const us = usersCountry === 'US' ? true : false;

    if (us) {
      // IN US

      onUSACountryDetected();
    } else {
      restService
        .getCryptoAddresses()
        .then((res) => {
          // Check if incoming address exists
          let incomingAddress: any;
          res.forEach((address: any) => {
            if (address.status === 'incoming') {
              incomingAddress = address;
            }
          });

          if (incomingAddress) {
            if (incomingAddress.chain === 'ETH') {
              if (!isBrowserSupported()) {
                onBrowserNotSupported();
              } else if (!(window as any).ethereum) {
                onMetaMaskNotInstalled();
              } else {
                if ((window.ethereum as any)['isBraveWallet']) {
                  onMetaMaskNotInstalled();
                }

                // Store purchase details in store
                setNumberOfBits(String(selectedBits));

                if (costOfSelected) {
                  setCostOfSelected(costOfSelected);
                }

                metamaskClient.connect(incomingAddress.nickname).then(() => {
                  onWalletConnected();
                });
              }
            } else {
              // Switch chain
              next({ state: QuickFlowStates.ConnectWallet });
            }
          } else {
            onNoAvailableAddresses();
          }
        })
        .catch((error) => {
          setGlobalBanner({
            title: 'Failed to retrieve addresses:',
            text: error.message,
          });
        });
    }
  }

  const paymentOptions = () => {
    return (
      <PaymentOptionsContainer>
        <PaymentOption
          style={{ width: '125px' }}
          onClick={()=>{}}
          className="use-crypto-button">
          <IconContainer>
            <Cryptocurrency height={50} width={50} fill={theme.colors.yellow50} />
          </IconContainer>
          <TextContent
            fontSize={viewport.width < 576 ? 'fz16' : 'fz18'}
            fontColor={theme.colors.white50}
            text="Buy using crypto currency"
          />
        </PaymentOption>
        <PaymentOption onClick={usingCardHandler} className="use-card-button">
          <IconContainer>
            <Card />
          </IconContainer>
          <TextContent
            fontSize={viewport.width < 576 ? 'fz16' : 'fz18'}
            fontColor={theme.colors.white}
            text="Buy using credit/debit card"
          />
        </PaymentOption>
      </PaymentOptionsContainer>
    );
  };

  return (
    <SBModal
      isOpen={isOpen}
      width="390px"
      mobileWidth="100%"
      height="662px"
      top={getTopPosition('10%')}
      mobileTop="2%"
      withProceedingText={true}
      withFeeText={true}
      feeIncludedInPrice={false}
      feeBottomPos="8%"
      content={
        <>
          <CloseButton
            onClick={() => {
              onClose();
            }}>
            X
          </CloseButton>

          <BoldText
            text={
              type === PaymentModalType.Quickflow ? 'reservation' : 'add funds'
            }
            fontWeight="bold"
            fontSize={viewport.width >= 576 ? 'fz48' : 'fz30'}
          />
          <Spacer height={20} />
          {header}
          {type === PaymentModalType.Quickflow ? (
            <Spacer height={20} />
          ) : (
            <TextContent
              text="Choose how to fund your wallet"
              fontColor={theme.colors.yellow}
              fontSize="fz24"
              fontWeight="bold"
            />
          )}
          <Spacer height={10} />
          <TextContent fontSize="fz18" fontColor={theme.colors.red} text="" />
          <Spacer height={35} />
          {paymentOptions()}
          <Spacer height={50} />
          {/* <RemainingBits /> */}
        </>
      }
    />
  );
};

const TextContent = styled(Typography)<{
  withUnderline?: boolean;
  withCursorPointer?: boolean;
  gap?: number;
}>`
  letter-spacing: -0.03em;
  flex-shrink: 0;
  ${(props) => props.withUnderline && 'text-decoration: underline'};
  ${(props) => props.withCursorPointer && 'cursor: pointer;'};
`;

const IconContainer = styled.div`
  width: 63px;
  display: flex;
  justify-content: center;
  transform: scale(0.7);

  @media (min-width: 576px) {
    transform: none;
  }
`;

const CloseButton = styled.div`
  color: white;
  position: absolute;
  top: 28px;
  right: 25px;
  font-size: 20px;
  cursor: pointer;
`;

const BoldText = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  letter-spacing: -0.03em;
`;

const PaymentOptionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 20px;
`;

const PaymentOption = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  text-align: center;
  max-width: 130px;
  line-height: 30px;
  cursor: pointer;
`;

export default memo(AddFundsOptions);
