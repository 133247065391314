import { action } from 'easy-peasy';
import { StyleModel } from './styleModel.types';

export const style: StyleModel = {
  showHeader: true,
  showFCABanner: false,
  isHeaderTransparent: false,
  isLogoShownInHeader: false,
  isMainFooterHidden: false,
  useTraparentHeaderSwitch: false,
  setShowHeader: action((state, payload) => {
    state.showHeader = payload;
  }),
  setShowFCABanner: action((state, payload) => {
    state.showFCABanner = payload;
  }),
  setHeaderTransparent: action((state, payload) => {
    state.isHeaderTransparent = payload;
  }),
  setLogoShownInHeader: action((state, payload) => {
    state.isLogoShownInHeader = payload;
  }),
  setMainFooterHidden: action((state, payload) => {
    state.isMainFooterHidden = payload;
  }),
  setUseTraparentHeaderSwitch: action((state, payload) => {
    state.useTraparentHeaderSwitch = payload;
  }),
};
