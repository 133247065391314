import React, { useMemo, useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { useStoreActions, useStoreState } from 'store/store';
import { useViewport } from 'use-viewport';
import { Link } from 'react-router-dom';
import {
  AUTHENTICATE_MUTATION,
  AUTHENTICATE_EMAIL_MUTATION,
} from 'modules/auth/graphql/Mutations.graphql';
import * as Yup from 'yup';
import jwtDecode from 'jwt-decode';

import WithRouteAnimation from 'common/hoc/WithRouteAnimation';
import Eye from 'common/icons/Eye.icon';
import EyeClose from 'common/icons/EyeClose.icon';
import Lock from 'common/icons/Lock.icon';
import UserProfile from 'common/icons/UserProfile.icon';
import PageContainer from 'common/layout/PageContainer';
import theme from 'theme/theme';

import './styles/Login.css';

import {
  ErrorMessage,
  ContentButton,
  FirstButton,
  ForgotPasswordButton,
  Form,
  Wrapper,
  HeadContent,
  HeadingSection,
  Icon,
  Image,
  ImageContainer,
  ImageGradient,
  InputContainer,
  InputWithIcon,
  PasswordVisibility,
  StrapLineTypography,
  //LoginContainer,
  //LoginLink,
  StrapLineTypographyContainer,
  TextContent,
} from './styled/Login.styled';

import AccountBanner from '../assets/login.jpg';
import {
  AUTHENTICATE_MUTATION as AUTHENTICATE_MUTATION_TYPE,
  AUTHENTICATE_EMAIL_MUTATION as AUTHENTICATE_EMAIL_MUTATION_TYPE,
  AUTHENTICATE_VARIABLES,
  AUTHENTICATE_EMAIL_VARIABLES,
} from '../types';
import { xs } from 'utils/constants';
import ErrorModal from 'common/components/Error/ErrorModal';


interface LocationState {
  from: {
    pathname: string;
  };
  banner: boolean;
}

const VALIDATION_SCHEMA = Yup.object().shape({
  userName: Yup.string().required('Username is required'),
  password: Yup.string().required('Password is required'),
});

function validateEmail(email: string) {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
}

const Login = () => {
  const navigate = useNavigate();
  const { showSpace } = useIntercom();
  const location = useLocation();
  const routerState = useLocation().state as LocationState;
  const viewport = useViewport();
  const isAuth = useStoreState((state) => state.authentication.isAuthenticated);

  const [showPassword, setShowPassword] = useState<boolean>(true);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

  const initialValues = useMemo(
    () => ({
      userName: '',
      password: '',
    }),
    [],
  );

  const setAccessToken = useStoreActions(
    (actions) => actions.authentication.setAccessToken,
  );

  const setRefreshToken = useStoreActions(
    (actions) => actions.authentication.setRefreshToken,
  );

  const setGlobalBanner = useStoreActions(
    (actions) => actions.globalbanner.setGlobalBanner,
  );

  const handleSupport = () => {
    setShowErrorModal(false)
    showSpace('messages')
  };

  useEffect(() => {
    if (isAuth) {
      navigate('/library/releases');
    }
  }, [isAuth]);

  /* Display unauth banner after redirect to login
   * Logout stores 'user_logout' in local storage. This
   * key/value is used to prevent unauth banner showing
   * on logout */
  useEffect(() => {
    if (
      routerState &&
      routerState.banner &&
      window.localStorage.getItem('user_logout') != 'logout'
    ) {
      window.localStorage.removeItem('user_logout');
      setGlobalBanner({
        title: 'Login required.',
        text: 'Please login below.',
      });
    } else {
      window.localStorage.removeItem('user_logout');
    }
  }, []);

  const hideGlobalBanner = useStoreActions(
    (actions) => actions.globalbanner.hideGlobalBanner,
  );

  const setExpiryTime = useStoreActions(
    (actions) => actions.authentication.setExpiryTime,
  );

  const setPreviousUrl = useStoreActions(
    (actions) => actions.location.setPreviousUrl,
  );

  const setEmailConfirm = useStoreActions(
    (actions) => actions.authentication.setEmailConfirm,
  );

  const setUserSlug = useStoreActions(
    (actions) => actions.authentication.setSlug,
  );

  const setOnboarding = useStoreActions(
    (actions) => actions.authentication.setOnboarding,
  );

  const { previousUrl } = useStoreState((state) => state.location);

  const { values, handleChange, handleSubmit, errors, touched } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: submitHandler,
  });

  const [auth, { loading }] = useMutation<
    AUTHENTICATE_MUTATION_TYPE,
    AUTHENTICATE_VARIABLES
  >(AUTHENTICATE_MUTATION, {
    variables: { username: values.userName, password: values.password },
  });

  const [authEmail, { loading: loadingEmail }] = useMutation<
    AUTHENTICATE_EMAIL_MUTATION_TYPE,
    AUTHENTICATE_EMAIL_VARIABLES
  >(AUTHENTICATE_EMAIL_MUTATION, {
    variables: { email: values.userName, password: values.password },
  });

  function forgotPasswordHandler() {
    navigate('/remind-password');
  }

  async function submitHandler() {

    try {
      let response: any;
      let authData: any;

      const isEmail = validateEmail(values.userName);
      if (isEmail) {
        response = await authEmail();
      } else {
        response = await auth();
      }

      if (!response.errors) {
        if (response.data) {
          let isArtist = false;
          let user_id = ''

          if (isEmail) {
            setAccessToken(response.data.authenticateEmail.auth.accessToken);
            setRefreshToken(response.data.authenticateEmail.auth.refreshToken);
            setEmailConfirm(
              response.data.authenticateEmail.auth.isEmailConfirmed,
            );
            authData = response.data.authenticateEmail.auth

            const decoded: any = jwtDecode(
              response.data.authenticateEmail.auth.accessToken,
            );

            setExpiryTime((decoded as any).exp);
            if ((decoded as any).role === 'app_artist') {
              isArtist = true;
            }
            setUserSlug(values.userName);
            
            if (decoded.user_id) {
              user_id = decoded['user_id']
            }
            
            (window as any).gtag("event", "login", {
              method: "email"
            });

          } else {
            setAccessToken(response.data.authenticate.auth.accessToken);
            setRefreshToken(response.data.authenticate.auth.refreshToken);
            setEmailConfirm(response.data.authenticate.auth.isEmailConfirmed);
            authData = response.data.authenticate.auth
            const decoded: any = jwtDecode(
              response.data.authenticate.auth.accessToken,
            );
            setUserSlug(values.userName);
            setExpiryTime((decoded as any).exp);

            if ((decoded as any).role === 'app_artist') {
              isArtist = true;
            }

            if (decoded.user_id) {
              user_id = decoded['user_id']
            }

            (window as any).gtag("event", "login", {
              method: "username"
            });
          }

          hideGlobalBanner();

          // If email is not confirmed, redirect to email confirm screen
          if (
            (isEmail &&
              !response.data.authenticateEmail.auth.isEmailConfirmed) ||
            (!isEmail && !response.data.authenticate.auth.isEmailConfirmed)
          ) {
            navigate('/confirm-email', {
              state: {
                id: user_id
              },
            });
          } else {

            if (!isArtist) {

              setOnboarding(authData.fcaStep)

            }
            if (previousUrl) {
              setPreviousUrl('');
              navigate(previousUrl);
            } else if (location.state) {
              navigate((location.state as LocationState).from.pathname);
            } else {
              if (isArtist) {
                navigate('/artist/dashboard');
              } else {
                navigate('/library/hot-releases');
              }
            }
          }
        }
      }
    } catch (e: any) {
      e
      setShowErrorModal(true)
    }
  }

  const passwordVisibilityHandler = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    const enterKeydown = (e: KeyboardEvent) => {
      if (e.key === 'Enter') handleSubmit();
    };

    document.addEventListener('keydown', enterKeydown);

    return () => {
      document.removeEventListener('keydown', enterKeydown);
    };
  }, []);

  return (
    <PageContainer pageTitle="Login | SongBits">
      <ImageContainer>
        <Image src={AccountBanner} />
        <ImageGradient banner={AccountBanner} />
      </ImageContainer>
      <Wrapper>
        <HeadingSection>
          <StrapLineTypographyContainer>
            <div style={{ display: 'flex' }}>
              <HeadContent
                text={viewport.width < xs ? 'Welcome' : 'welcome'}
                fontSize="fz100"
                fontWeight="bold"
              />
              &nbsp;
              <StrapLineTypography
                text="."
                fontSize="fz100"
                fontWeight="bold"
                fontColor={theme.colors.yellow}
              />
              {viewport.width < xs ? <>&nbsp;</> : <>&nbsp;&nbsp;</>}
              <StrapLineTypography
                text="."
                fontSize="fz100"
                fontWeight="bold"
                fontColor={theme.colors.yellow}
              />
              {viewport.width < xs ? <>&nbsp;</> : <>&nbsp;&nbsp;</>}
              <StrapLineTypography
                text="."
                fontSize="fz100"
                fontWeight="bold"
              />
            </div>
          </StrapLineTypographyContainer>
        </HeadingSection>

        <Form>
          <TextContent
            text="Login"
            fontSize="fz24"
            fontWeight="bold"
            letterSpacing="-0.03em"
            fontColor={theme.colors.yellow}
          />

          <InputContainer>
            <Icon>
              <UserProfile />
            </Icon>
            <InputWithIcon
              height={viewport.width < xs ? 65 : 72}
              type="text"
              withBottomLine
              label="Username"
              value={values.userName}
              inputName={'username'}
              autoComplete="username email"
              error={
                Boolean(errors.userName && touched.userName)
                  ? errors.userName
                  : undefined
              }
              onChange={handleChange('userName')}
            />
          </InputContainer>

          <InputContainer>
            <Icon>
              <Lock />
            </Icon>
            <InputWithIcon
              height={viewport.width < xs ? 65 : 72}
              withBottomLine
              label="Password"
              value={values.password}
              inputName={'password'}
              autoComplete="current-password"
              type={showPassword ? 'password' : 'text'}
              error={
                Boolean(errors.password && touched.password)
                  ? errors.password
                  : undefined
              }
              onChange={handleChange('password')}
            />
            <PasswordVisibility className='password-toggle' onClick={passwordVisibilityHandler}>
              {showPassword ? <Eye /> : <EyeClose />}
            </PasswordVisibility>
          </InputContainer>

          <ForgotPasswordButton
            label="Forgot Password?"
            onClick={forgotPasswordHandler}
            isTextBold={false}
            className="forgot-password-button"
          />

          <FirstButton
            className="login-button"
            height={45}
            width={173}
            borderRadius={50}
            isLoading={loading || loadingEmail}
            disabled={loading || loadingEmail}
            borderColor={theme.colors.white}
            label={
              <div style={{ display: 'flex' }}>
                <ContentButton text="log" fontSize="fz16" fontWeight="bold" />
                &nbsp;
                <ContentButton
                  text="in"
                  fontSize="fz16"
                  fontColor={theme.colors.yellow}
                  fontWeight="bold"
                />
              </div>
            }
            onClick={handleSubmit}
          />

          {/*<>
            <LoginContainer>
              Not got a SongBits account?{'  '}
              <LoginLink className={'create-account-link'} to="/create-account">Create Account</LoginLink>
            </LoginContainer>
          </>  */}
            
          <ErrorModal show={showErrorModal} 
            onClose={() => {
              setShowErrorModal(false)
            }} 
            content={<><ErrorMessage>
              Invalid username or password.{'\n\n'}Please try again or use{' '}
              <Link
                to="/remind-password"
                onClick={() => setShowErrorModal(false)}>
                forgot password
              </Link>
              {'\n\n'}Alternatively contact{' '}
              <Link to="#" onClick={() => handleSupport()}>
                support
              </Link>
            </ErrorMessage></>} />

        </Form>
      </Wrapper>
    </PageContainer>
  );
};

export default WithRouteAnimation(Login, false);
