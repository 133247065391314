import React from 'react';

import { IconProps } from '../common.types';

const LogoSmallBeta = ({ width = 46, height = 40 }: IconProps) => (
  <svg
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 1742 1539"
    xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M872.053 851.511C895.586 851.511 914.664 832.433 914.664 808.9L914.664 42.612C914.664 19.0786 895.586 0.00101121 872.053 0.00101163C848.52 0.00101205 829.442 19.0786 829.442 42.612L829.442 808.9C829.442 832.433 848.52 851.511 872.053 851.511Z" fill="#FFD600"/>
<path fillRule="evenodd" clipRule="evenodd" d="M1025.45 851.51C1048.99 851.51 1068.06 832.432 1068.06 808.899L1068.06 297.567C1068.06 274.033 1048.99 254.956 1025.45 254.956C1001.92 254.956 982.842 274.033 982.842 297.567L982.842 808.899C982.842 832.432 1001.92 851.51 1025.45 851.51Z" fill="white"/>
<path fillRule="evenodd" clipRule="evenodd" d="M718.654 851.51C695.121 851.51 676.043 832.432 676.043 808.899L676.043 42.6109C676.043 19.0775 695.121 -8.74213e-05 718.654 -8.70021e-05C742.187 -8.6583e-05 761.265 19.0775 761.265 42.6109L761.265 808.899C761.265 832.432 742.187 851.51 718.654 851.51Z" fill="white"/>
<path fillRule="evenodd" clipRule="evenodd" d="M1195.9 851.51C1219.43 851.51 1238.51 832.432 1238.51 808.899L1238.51 214.475C1238.51 190.942 1219.43 171.864 1195.9 171.864C1172.36 171.864 1153.29 190.942 1153.29 214.475L1153.29 808.899C1153.29 832.432 1172.36 851.51 1195.9 851.51Z" fill="white"/>
<path fillRule="evenodd" clipRule="evenodd" d="M548.211 851.51C524.677 851.51 505.6 832.432 505.6 808.899L505.6 468.011C505.6 444.477 524.677 425.4 548.211 425.4C571.744 425.4 590.822 444.477 590.822 468.011L590.822 808.899C590.822 832.432 571.744 851.51 548.211 851.51Z" fill="white"/>
<path fillRule="evenodd" clipRule="evenodd" d="M1362.08 851.51C1385.61 851.51 1404.69 832.432 1404.69 808.899L1404.69 510.622C1404.69 487.088 1385.61 468.011 1362.08 468.011C1338.55 468.011 1319.47 487.088 1319.47 510.622L1319.47 808.899C1319.47 832.432 1338.55 851.51 1362.08 851.51Z" fill="white"/>
<path fillRule="evenodd" clipRule="evenodd" d="M382.027 851.51C358.494 851.51 339.416 832.432 339.416 808.899L339.416 300.407C339.416 276.874 358.494 257.796 382.027 257.796C405.56 257.796 424.638 276.874 424.638 300.407L424.638 808.899C424.638 832.432 405.56 851.51 382.027 851.51Z" fill="white"/>
<path fillRule="evenodd" clipRule="evenodd" d="M1532.52 851.51C1556.06 851.51 1575.13 832.432 1575.13 808.899L1575.13 382.789C1575.13 359.255 1556.06 340.178 1532.52 340.178C1508.99 340.178 1489.91 359.255 1489.91 382.789L1489.91 808.899C1489.91 832.432 1508.99 851.51 1532.52 851.51Z" fill="white"/>
<path fillRule="evenodd" clipRule="evenodd" d="M211.584 851.51C188.05 851.51 168.973 832.432 168.973 808.899L168.973 723.677C168.973 700.143 188.05 681.066 211.584 681.066C235.117 681.066 254.195 700.143 254.195 723.677L254.195 808.899C254.195 832.432 235.117 851.51 211.584 851.51Z" fill="white"/>
<path fillRule="evenodd" clipRule="evenodd" d="M1698.71 851.51C1722.24 851.51 1741.32 832.432 1741.32 808.899L1741.32 638.455C1741.32 614.921 1722.24 595.844 1698.71 595.844C1675.17 595.844 1656.1 614.921 1656.1 638.455L1656.1 808.899C1656.1 832.432 1675.17 851.51 1698.71 851.51Z" fill="white"/>
<path fillRule="evenodd" clipRule="evenodd" d="M45.4001 851.51C21.8667 851.51 2.78906 832.432 2.78907 808.899L2.7891 510.622C2.7891 487.088 21.8667 468.011 45.4001 468.011C68.9335 468.011 88.0111 487.088 88.0111 510.622L88.0111 808.899C88.0111 832.432 68.9335 851.51 45.4001 851.51Z" fill="white"/>
<rect x="17.5" y="975.5" width="1706" height="546" rx="273" stroke="#FFD600" strokeWidth="35"/>
<path d="M343.4 1388V1109.2H427.4C520.6 1109.2 574.2 1160.4 574.2 1250.8C574.2 1339.6 522.2 1388 427 1388H343.4ZM406.2 1330.4H420.6C477.4 1330.4 511 1315.6 511 1248.4C511 1183.6 478.6 1166.8 426.2 1166.8H406.2V1330.4ZM598.588 1388V1109.2H785.788V1166.8H661.388V1214.8H779.788V1272.8H661.388V1330.4H785.788V1388H598.588ZM1033.31 1109.2H1122.91V1388H1060.11V1199.6L1003.31 1362.8H942.906L886.106 1200.4V1388H823.306V1109.2H912.906L972.506 1287.6L1033.31 1109.2ZM1283.96 1395.2C1203.16 1395.2 1147.56 1336.4 1147.56 1248.8C1147.56 1160.8 1203.16 1102 1283.96 1102C1365.56 1102 1420.76 1160.4 1420.76 1248.8C1420.76 1336.8 1364.76 1395.2 1283.96 1395.2ZM1283.96 1335.2C1329.16 1335.2 1357.56 1302 1357.56 1248.8C1357.56 1195.2 1329.16 1162 1283.96 1162C1239.16 1162 1210.36 1194.4 1210.36 1248.8C1210.36 1302.8 1239.16 1335.2 1283.96 1335.2Z" fill="white"/>  </svg>
);

export default LogoSmallBeta;
