import Footer from 'common/layout/Footer';
import HamburgerMenu from 'common/layout/HamburgerMenu';

import { useStoreState } from 'store/store';
import Header from 'common/layout/Header/Header';
import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useViewport } from 'use-viewport';
import { xs } from 'utils/constants';


const Layout = () => {
  const viewport = useViewport();
  const location = useLocation();
  const navigate = useNavigate();

  const regexArtist = `\/\artist[0-9]\/`;
  const isArtistPage =
    location.pathname.search(regexArtist) === 0 ? true : false;

  const [isHamburgerOpen, setHamburgerOpen] = useState<boolean>(false);

  const toggleHamburger = () => {
    setHamburgerOpen(!isHamburgerOpen);
  };

  const showHeaderFooter = () => {
    if (
      location.pathname === '/connect-wallet' ||
      location.pathname === '/addcryptofunds'
    ) {
      return false;
    } else {
      return true;
    }
  };

  const isEmailConfirmed = useStoreState(
    (state) => state.authentication.isEmailConfirmed,
  );

  const isAuthenticated = useStoreState(
    (state) => state.authentication.isAuthenticated,
  );

  const isLogoShownFromStore = useStoreState(
    (states) => states.style.isLogoShownInHeader,
  );

  const isLogoHeaderShown =
    viewport.width < xs || // Show logo on small devices
    !isAuthenticated || // Show logo for non-authorized users on all pages except Home
    isAuthenticated || // Show logo for authorized users on all pages
    isLogoShownFromStore; // Show logo for specific pages
  
  // This code redirects user if email hasn't been confirmed - doenst allow them to navigate anywhere else on the site until confirmed
  useEffect(() => {
    const availablePathnames = [
      '/confirm-email',
      '/login',
      '/create-account',
      '/change-email',
    ];

    if (
      isAuthenticated &&
      !isEmailConfirmed &&
      !availablePathnames.includes(location.pathname)
    ) {
      navigate('/confirm-email');
    }
  });

  return (
    <>
      {isHamburgerOpen ? (
        <BackDrop onClick={() => setHamburgerOpen(false)} />
      ) : null}
      {showHeaderFooter() ? (
        <>
          <Header
            isLogoVisible={isLogoHeaderShown}
            toggleHamburger={toggleHamburger}
            isArtistPage={isArtistPage}
          />
          <HamburgerMenu
            isHamburgerOpen={isHamburgerOpen}
            setHamburgerOpen={setHamburgerOpen}
          />
        </>
      ) : (
        <></>
      )}

      <Outlet />

      {isAuthenticated ? (
        <></>
      ) : (
        <Footer />
      )}
    </>
  );
};

const BackDrop = styled.div`
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  position: absolute;
`;

export default Layout;
