import React, { FC } from 'react';
import styled from 'styled-components';
import ArtistStat from '../../components/StatContainer/StatContainer';

import { ArtistDashboardData } from 'services/types';

interface ArtistStatSectionProps {
  artistData?: ArtistDashboardData;
}

const ArtistStatSection: FC<ArtistStatSectionProps> = ({ artistData }) => {
  return (
    <StatContainer>
      <ArtistStat
        value={`${
          artistData ? Number(artistData.total_bits_sold).toLocaleString() : 0
        }`}
        desc="total bits sold"
        tooltipText={'The total number of bits \nthat have been sold over \nall releases'}
      />
      <BottomLine />
      <ArtistStat
        value={`$${artistData?.total_gross_revenue ? Number(artistData?.total_gross_revenue).toLocaleString() : '0.00'}`}
        desc="total gross revenue"
        tooltipText={'The total number of revenue\n that has been made across \nall releases'}
      />
      <BottomLine />
      <ArtistStat
        value={artistData?.total_new_fans.toString() ?? '0'}
        desc="total signups"
        tooltipText={'The total number of waitlist \nsignups of all time'}
      />
      <BottomLine />
      <ArtistStat
        value={artistData?.new_fans_this_month?.toString() ?? '0'}
        desc="new signups this month"
        tooltipText={'The number of waitlist \nsignups this current month'}
      />
    </StatContainer>
  );
};

const StatContainer = styled.div``;

const BottomLine = styled.div`
  height: 1px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  opacity: 0.5;
  background-color: ${(props) => props.theme.colors.yellow};
  background: linear-gradient(
    0.25turn,
    ${(props) => props.theme.colors.yellow},
    ${(props) => props.theme.colors.yellow},
    ${(props) => props.theme.colors.transparent}
  );
`;

export default ArtistStatSection;
