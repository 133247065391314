import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useViewport } from 'use-viewport';

import TextContentContainer from 'common/components/TextContentContainer/TextContentContainer';
import WithRouteAnimation from 'common/hoc/WithRouteAnimation';
import PageContainer from 'common/layout/PageContainer';
import theme from 'theme/theme';

import Banner from '../assets/show-me-why.jpg';
import MobileBanner from '../assets/show-me-why-mobile.jpg';

import { useIntercom } from 'react-use-intercom';
import { footerHeight, footerMobileHeight, xlg, xs } from 'utils/constants';

import {
  ButtonsContainer,
  ContentButton,
  FirstButton,
  HeadContainer,
  HeadingSection,
  Image,
  ImageContainer,
  ImageGradient,
  LeftMarginButton,
  StrapLineTypography,
  StrapLineTypographyContainer,
  TextContent,
  TextContentInnerContainer,
  //TextContentInnerContainer,
} from './styled/ShowMeWhy.styled';

const ShowMeWhy = () => {
  const navigate = useNavigate();
  const viewport = useViewport();
  const { showSpace } = useIntercom();
  const handleFAQ = () => showSpace('help');

  const contentContainerHeight = useMemo(
    () =>
      viewport.width < xs ? '100%' : viewport.width >= xlg ? '450px' : '320px',
    [viewport.width],
  );

  const navigateToHandler = (route: string) => {
    navigate(route);
  };

  return (
    <PageContainer
      pageTitle='Show Me Why | SongBits'
      reduceFooter={`${footerHeight}px`}
      reduceFooterMobile={`${footerMobileHeight}px`}>
      <ImageContainer>
        <Image src={viewport.width < 576 ? MobileBanner : Banner} />
        <ImageGradient banner={viewport.width < 576 ? MobileBanner : Banner} />
      </ImageContainer>
      <HeadContainer>
        <HeadingSection>
          <StrapLineTypographyContainer>
            <StrapLineTypography
              text={viewport.width < 576 ? 'show ' : 'show me '}
              fontSize="fz100"
              fontWeight="bold"
              letterSpacing="-0.05em"
            />

            <div style={{ display: 'flex' }}>
              {viewport.width < 576 && (
                <StrapLineTypography
                  text="me "
                  fontSize="fz100"
                  fontWeight="bold"
                  fontColor={theme.colors.white}
                  letterSpacing="-0.05em"
                />
              )}
              <StrapLineTypography
                text="why"
                fontSize="fz100"
                fontWeight="bold"
                fontColor={theme.colors.yellow}
                letterSpacing="-0.05em"
              />
              &nbsp;
              <StrapLineTypography
                text="."
                fontSize="fz100"
                fontWeight="bold"
                fontColor={theme.colors.yellow}
                letterSpacing="0.035em"
              />
              &nbsp;&nbsp;
              <StrapLineTypography
                text="."
                fontSize="fz100"
                fontWeight="bold"
                fontColor={theme.colors.yellow}
                letterSpacing="0.035em"
              />
              &nbsp;&nbsp;
              <StrapLineTypography
                text="."
                fontSize="fz100"
                fontWeight="bold"
                fontColor={theme.colors.white}
                letterSpacing="0.035em"
              />
            </div>
          </StrapLineTypographyContainer>

          <TextContentContainer
            height={contentContainerHeight}
            altText="We believe artists should be in full control of their music.">
            <TextContent
              text="Around just 10% of recorded music revenue goes to artists in the traditional music model."
              fontSize={viewport.width < 576 ? 'fz14' : 'fz18'}
              fontColor={theme.colors.white}
            />
            <TextContent
              text="The remaining is consumed by record labels, publishers, streaming platforms, lawyers, and fees. That's not fair."
              fontSize={viewport.width < 576 ? 'fz14' : 'fz18'}
              fontColor={theme.colors.white}
            />
            <TextContent
              text="Songbits empowers artists to regain complete control and offer fans and collectors a VIP pass to a world of ultimate fandom. You get special perks that the artist offers, like exclusive shows, hard-to-get tickets, cool merchandise, chances to meet the artist, and even limited edition releases."
              fontSize={viewport.width < 576 ? 'fz14' : 'fz18'}
              fontColor={theme.colors.white}
            />
            <TextContent
              text={
                <TextContentInnerContainer>
                  More questions? Check our{' '}
                  <span
                    style={{ color: '#FFD600', cursor: 'pointer' }}
                    onClick={() => {handleFAQ()}}>
                    FAQ here
                  </span>
                  .
                </TextContentInnerContainer>
              }
              fontSize="fz14"
              fontColor={theme.colors.white}
            />
          </TextContentContainer>
        </HeadingSection>

        <ButtonsContainer>
          <FirstButton
            borderRadius={50}
            borderColor={theme.colors.white}
            label={
              <div style={{ display: 'flex' }}>
                <ContentButton
                  text="show me"
                  fontSize="fz16"
                  fontWeight="bold"
                />
                &nbsp;
                <ContentButton
                  text="how"
                  fontSize="fz16"
                  fontColor={theme.colors.yellow}
                  fontWeight="bold"
                />
              </div>
            }
            onClick={() => {
              navigateToHandler('/how');
            }}
          />
          <LeftMarginButton
            borderRadius={50}
            borderColor={theme.colors.white}
            opacity={0.5}
            label={
              <div style={{ display: 'flex' }}>
                <ContentButton
                  text="show me"
                  fontSize="fz16"
                  fontWeight="bold"
                />
                &nbsp;
                <ContentButton
                  text="why"
                  fontSize="fz16"
                  fontColor={theme.colors.yellow}
                  fontWeight="bold"
                />
              </div>
            }
            onClick={() => {
              navigateToHandler('/why');
            }}
          />
          <LeftMarginButton
            borderRadius={50}
            borderColor={theme.colors.white}
            label={
              <div style={{ display: 'flex' }}>
                <ContentButton
                  text="show me"
                  fontSize="fz16"
                  fontWeight="bold"
                />
                &nbsp;
                <ContentButton
                  text="who"
                  fontSize="fz16"
                  fontColor={theme.colors.yellow}
                  fontWeight="bold"
                />
              </div>
            }
            onClick={() => {
              navigateToHandler('/who');
            }}
          />
          <LeftMarginButton
          borderRadius={50}
          borderColor={theme.colors.white}
          label={
            <div style={{ display: 'flex' }}>
              <ContentButton
                text="show me"
                fontSize="fz16"
                fontWeight="bold"
              />
              &nbsp;
              <ContentButton
                text="songs"
                fontSize="fz16"
                fontColor={theme.colors.yellow}
                fontWeight="bold"
              />
            </div>
          }
          onClick={() => {
            navigateToHandler('/library/hot-releases');
          }}
        />

        </ButtonsContainer>
      </HeadContainer>
    </PageContainer>
  );
};

export default WithRouteAnimation(ShowMeWhy);
