import React, { useMemo } from 'react';
import { useViewport } from 'use-viewport';

import PageContainer from 'common/layout/PageContainer';
import QuickLinks from 'common/components/QuickLinks/QuickLinks';
import QuickLinksMobile from 'common/components/QuickLinksMobile/QuickLinksMobile';

import PageLayout from 'common/components/PageLayout/PageLayout';


import { paypalTermsData } from 'utils/paypalTerms';
import { paypalPrivacyData } from 'utils/paypalPrivacy';
import '../../../theme/termsStyles.scss';

const PayPalLegal = () => {
  const viewport = useViewport();
  const quickLinks = useMemo(
    () => [
      {
        title: 'Terms & Conditions',
        link: '/legal/terms-conditions',
      },
      {
        title: 'Cookie Policy',
        link: '/legal/cookie-policy',
      },
      {
        title: 'Risk Summary',
        link: '/legal/risk-summary',
      },
      /*{
        title: 'Risk Factors',
        link: '/legal/risks',
      },*/
      /*{
        title: 'Community Guidelines',
        link: '/legal/community-guidelines',
      },*/
      {
        title: 'Company',
        link: '/company',
      },
    ],
    [],
  );

  const contentSection = (
    <>
      {viewport.width <= 767 ? (
        <QuickLinksMobile links={quickLinks} loading={false}></QuickLinksMobile>
      ) : (
        <></>
      )}
      <div className='terms' dangerouslySetInnerHTML={{ __html: paypalTermsData }}></div>
      <div className='terms' dangerouslySetInnerHTML={{ __html: paypalPrivacyData }}></div>
    </>
  );

  return (
    <PageContainer  pageTitle='PayPal Policies | SongBits'>
      <PageLayout
        title="PayPal Policies"
        is2ColumnLayout
        sections={[
          {
            content: contentSection,
          },
          {
            content: (
              <>
                {viewport.width <= 767 ? (
                  <></>
                ) : (
                  <QuickLinks links={quickLinks}></QuickLinks>
                )}
              </>
            ),
          },
        ]}
      />
    </PageContainer>
  );
};

export default PayPalLegal;
