import React, { useState } from 'react';
import styled from 'styled-components';
import { useViewport } from 'use-viewport';

import Button from 'common/components/Button/Button';
import Typography from 'common/components/Typography/Typography';
import Edit from 'common/icons/Edit.icon';
import theme from 'theme/theme';

import SBModal from 'common/components/Modal/SBModal';
import ChangeEmailModal from '../ChangeEmailModal/ChangeEmailModal';

const ChangeEmail = ({ email }: { email: string }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const viewport = useViewport();

  const onClick = () => {
    setShowModal(true);
  };

  return (
    <Container>
      <EmailButtonContainer>
        <Typography
          text={email}
          fontSize="fz18"
          fontColor={theme.colors.gray}
        />
        <Button
          className='change-email-button'
          label={
            viewport.width >= 576 ? (
              <Edit width={20} height={20} />
            ) : (
              <Edit width={14} height={14} />
            )
          }
          onClick={onClick}
        />
      </EmailButtonContainer>
      <BottomLine />
      <SBModal
        isOpen={showModal}
        height={`${viewport.width >= 576 ? '373px' : '324px'}`}
        top="25%"
        content={
          <ChangeEmailModal
            email={email}
            onChange={() => {
              setShowModal(false);
            }}
          />
        }
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 60px;
  position: relative;
`;

const EmailButtonContainer = styled.div`
  height: 60px;
  width: 100%;
  padding: 0 20px 0 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const BottomLine = styled.div`
  bottom: 0;
  height: 1px;
  width: 105%;
  position: absolute;
  opacity: 0.5;
  margin-left: -5px;
  background-color: ${(props) => props.theme.colors.yellow};
  background: linear-gradient(
    0.25turn,
    ${(props) => props.theme.colors.yellow},
    ${(props) => props.theme.colors.yellow},
    ${(props) => props.theme.colors.transparent}
  );
`;

export default ChangeEmail;
