import React, { useEffect } from 'react';
import { Routes as Switch, Route, Navigate } from 'react-router-dom';
import styled from 'styled-components';

import { useStoreActions } from 'store/store';

import Legal from './pages/Legal';
import CookiePolicy from './pages/CookiePolicy';
import PrivacyPolicy from './pages/PrivacyPolicy';
import PayPalLegal from './pages/PayPalLegal';
import RiskSummary from './pages/RiskSummary';
//import CommunityGuidelines from './pages/CommunityGuidelines';
//import RiskFactors from './pages/RiskFactors';

const LegalRouter = () => {
  const setIsHeaderTransparent = useStoreActions(
    (state) => state.style.setHeaderTransparent,
  );

  const setMainFooterHidden = useStoreActions(
    (state) => state.style.setMainFooterHidden,
  );

  useEffect(() => {
    setIsHeaderTransparent(false);
    setMainFooterHidden(true);

    return () => {
      setMainFooterHidden(false);
    };
  }, []);

  return (
    <RouterContainer>
      <Switch>
        <Route path="" element={<Navigate to={'terms-conditions'} />} />
        <Route path="terms-conditions" element={<Legal />} />
        <Route path="cookie-policy" element={<CookiePolicy />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="paypal" element={<PayPalLegal />} />
        <Route path="risk-summary" element={<RiskSummary />} />
        {/*<Route path="community-guidelines" element={<CommunityGuidelines />} />*/}
        {/*<Route path="risks" element={<RiskFactors />} />*/}
      </Switch>
    </RouterContainer>
  );
};

const RouterContainer = styled.div`
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  position: relative;
  background-color: ${(props) => props.theme.colors.black};
`;

export default LegalRouter;
