import React, { FC, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

import Typography from 'common/components/Typography/Typography';
import DefaultButton from 'common/components/DefautButton/DefaultButton';

import ChainRectangleOutlinedIcon from 'common/icons/MyCollection/ChainRectangleOutlined.icon';
import ImportOutlinedIcon from 'common/icons/ImportOutlined.icon';
import ArrowBothSidesIcon from 'common/icons/ArrowBothSides.icon';
import ShareOutlinedIcon from 'common/icons/ShareOutlined.icon';
import CloseCircleIcon from 'common/icons/CloseCircle.icon';

import theme from 'theme/theme';
import NFTFullViewModal from '../NFTFullViewModal/NFTFullViewModal';
import restService from 'services/rest.service';
import { TransactionType } from 'modules/library/types';
import SBModal from 'common/components/Modal/SBModal';
import SongProfileShareModal from '../../SongProfileShareModal/SongProfileShareModal';
import { useStoreActions } from 'store/store';

interface NFTModalProps {
  show: boolean;
  songData: TransactionType;
  onClose: () => void;
  onChainClick: () => void;
}

const NFTModal: FC<NFTModalProps> = ({
  show,
  songData,
  onClose,
  onChainClick,
}) => {
  const [imageLoading, setImageLoading] = useState<boolean>(false);
  const [NFTLinkElement, setNFTLinkElement] = useState<HTMLAnchorElement>();
  const [imageUrl, setImageUrl] = useState<string>('');

  const [showNFTFullViewModal, setShowNFTFullViewModal] =
    useState<boolean>(false);
  const [showDowbloadedState, setShowDownloadedState] =
    useState<boolean>(false);
  const [showShareModal, setShowShareModal] = useState<boolean>(false);

  const setShowHeader = useStoreActions((state) => state.style.setShowHeader);

  useEffect(() => {
    if (!show) return;
    setImageLoading(true);

    restService
      .downloadNFTImage(songData.originaltransactionid)
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        setImageUrl(url);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          songData.title + ' - ' + songData.artist_name + '.jpeg',
        );
        setNFTLinkElement(link);
      })
      .catch((error) => {
        error
        //console.log(error);
      })
      .finally(() => {
        setShowHeader(false);
        setImageLoading(false);
      });

    return () => {
      setShowHeader(true);
    };
  }, [show]);

  function downloadNFTImage() {
    if (!NFTLinkElement) return;

    document.body.appendChild(NFTLinkElement);
    NFTLinkElement.click();
    setShowDownloadedState(true);

    setTimeout(() => {
      setShowDownloadedState(false);
    }, 2000);
  }

  if (!show || imageLoading) return null;

  return createPortal(
    <Wrapper>
      <Background url={imageUrl} />

      <Container url={imageUrl}>
        <ContainerShadow />

        <DownloadedContainer show={showDowbloadedState}>
          <Typography
            text="downloaded!"
            fontSize="fz14"
            fontWeight="bold"
            fontColor={theme.colors.black}
          />
        </DownloadedContainer>

        <TopContentContainer>
          <Typography
            text={'exclusive photo'}
            fontSize="fz14"
            fontWeight="bold"
          />
        </TopContentContainer>

        <ActionsContainer>
          <DefaultButton onClick={() => setShowShareModal(true)}>
            <ShareOutlinedIcon />
          </DefaultButton>

          <DefaultButton onClick={downloadNFTImage}>
            <ImportOutlinedIcon />
          </DefaultButton>

          <DefaultButton onClick={onChainClick}>
            <ChainRectangleOutlinedIcon width={27} height={27} />
          </DefaultButton>

          <DefaultButton onClick={() => setShowNFTFullViewModal(true)}>
            <ArrowBothSidesIcon width={29} height={29} />
          </DefaultButton>
        </ActionsContainer>

        <BottomContentContainer>
          <Typography
            text={'no. ' + String(songData.nftmetadataid).padStart(10, '0')}
            fontSize="fz14"
            fontWeight="bold"
          />
        </BottomContentContainer>
      </Container>

      <CloseContainer onClick={onClose}>
        <CloseCircleIcon />
      </CloseContainer>

      <SBModal
        isOpen={showShareModal}
        height={'430px'}
        width="434px"
        top="30%"
        content={
          <SongProfileShareModal
            shareUrl={`/${songData.artistslug}/${songData.songslug}`}
            imageLink={songData.file_url}
            onChange={() => {
              setShowShareModal(false);
            }}
          />
        }
      />

      <NFTFullViewModal
        show={showNFTFullViewModal}
        imageLink={imageUrl}
        onClose={() => setShowNFTFullViewModal(false)}
        onChainClick={() => {
          setShowNFTFullViewModal(false);
          onChainClick();
        }}
        onImportClick={() => {
          setShowNFTFullViewModal(false);
          downloadNFTImage();
        }}
        onShareClick={() => {
          setShowNFTFullViewModal(false);
          setShowShareModal(true);
        }}
      />
    </Wrapper>,
    document.body,
  );
};

const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;

  position: fixed;
  top: 0;
  left: 0;

  z-index: 1;
`;

const Background = styled.div<{ url: string }>`
  height: 100vh;
  width: 100vw;

  position: absolute;
  top: 0;
  left: 0;

  background-image: url(${(props) => props.url});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  filter: brightness(25%);
`;

const Container = styled.div<{ url: string }>`
  width: 300px;
  height: 300px;

  position: absolute;
  top: 18.6%;
  left: 50%;

  transform: translateX(-50%);

  border: 2px solid ${theme.colors.yellow};
  border-radius: 20px;

  background-image: url(${(props) => props.url});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  display: flex;
  justify-content: center;
  align-items: flex-end;

  padding: 18px;

  z-index: 1;
`;

const ContainerShadow = styled.div`
  width: 100%;
  height: 100px;

  position: absolute;
  bottom: 0;
  left: 0;

  border-radius: 0 0 20px 20px;

  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.73) 67.71%,
    #000 100%
  );
`;

const TopContentContainer = styled.div`
  width: 261px;
  height: 25px;

  position: absolute;
  top: 0;
  left: 50%;

  transform: translate(-50%, -100%);

  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid ${theme.colors.yellow};
  border-radius: 10px 10px 0 0;

  background: black;
`;

const BottomContentContainer = styled.div`
  width: 261px;
  height: 25px;

  position: absolute;
  bottom: 0;
  left: 50%;

  transform: translate(-50%, 100%);

  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid ${theme.colors.yellow};
  border-radius: 0 0 10px 10px;

  background: black;
`;

const CloseContainer = styled.div`
  position: absolute;
  bottom: 100px;
  left: 50%;

  transform: translateX(-50%);
`;

const ActionsContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
`;

const DownloadedContainer = styled.div<{ show: boolean }>`
  display: ${(props) => (props.show ? 'block' : 'none')};

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background: rgba(255, 255, 255, 0.5);

  padding: 10px;

  border: 1px solid ${theme.colors.yellow};
  border-radius: 10px;
`;

export default NFTModal;
