import React, { FC } from 'react';
import { useViewport } from 'use-viewport';
import styled from 'styled-components';

import Typography from 'common/components/Typography/Typography';
import DefaultButton from 'common/components/DefautButton/DefaultButton';
import PurchaseDetails from './components/PurchaseDetails/PurchaseDetails';

import DollarCircleIcon from 'common/icons/DollarCircle.icon';

import { TransactionType } from 'modules/library/types';

interface PurchaseInfoModal {
  songData: TransactionType;
  onClose: () => void;
}

const PurchaseInfoModal: FC<PurchaseInfoModal> = ({ songData, onClose }) => {
  const viewport = useViewport();

  const deatailsData = [
    {
      label: '# of bits purchased:',
      value: `${songData.countofbitspurchased} bits (1s)`,
    },
    {
      label: 'Price per Bit:',
      value: `$${songData.bit_price}`,
    },
    {
      label: 'Price paid:',
      value: `$${songData.data.price_paid} (${songData.price} USDC)`,
    },
  ];

  return (
    <Wrapper>
      <Header>
        <BoldText
          text="purchase info"
          fontWeight="bold"
          fontSize={viewport.width >= 576 ? 'fz48' : 'fz30'}
        />
        <CloseButton onClick={onClose}>x</CloseButton>
      </Header>

      <Content>
        <DollarCircleIcon height={34} width={34} />
        <PurchaseDetailsContainer>
          <Typography text={'Purchase summary for your bits:'} />

          {deatailsData.map((details, index) => (
            <PurchaseDetails key={index} {...details} />
          ))}
        </PurchaseDetailsContainer>
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 25px;
`;

const BoldText = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  letter-spacing: -0.03em;
`;

const CloseButton = styled(DefaultButton)`
  font-size: 20px;
  color: white;

  cursor: pointer;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const PurchaseDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export default PurchaseInfoModal;
