import React, { FC, memo, useEffect, useState } from 'react';
import SBModal from 'common/components/Modal/SBModal';
import { CardSchema } from '../types';
import styled from 'styled-components';
import Typography from 'common/components/Typography/Typography';
import theme from 'theme/theme';
import Button from 'common/components/Button/Button';
import Spacer from 'common/components/Spacer/Spacer';
import { useViewport } from 'use-viewport';
import restService, { PublicKey } from 'services/rest.service';
import store, { useStoreActions } from 'store/store';
import { BeatLoader } from 'react-spinners';
import { PaymentDetailsType } from 'modules/account/pages/components/Modals/CardPaymentModal';
import CVVModal from 'modules/account/pages/components/Modals/CVVModal';
import { useAppDispatch } from 'hooks/reduxToolkit';
import { setModalType, setShowState } from 'store-persist/reducers/modalSlice';
import { useAppSelector } from 'hooks/reduxToolkit';
import { checkCircleError } from 'utils/circle-errors';
// import RemainingBits from '../RemainingBits';
import { getTopPosition } from 'utils/functions';
// import ReactGA from 'react-ga4'

interface PaymentModalProps {
  isOpen: boolean;
  mostRecentCard: CardSchema;
  onClose: () => void;
  onCardPayment: () => void;
  onAddCard: (previousCard?: string) => void;
  purchaseExpired: () => void;
  onPaymentComplete: (id: string) => void;
  onPaymentFail: (text: string) => void;
  onStartAgain: () => void;
  header: JSX.Element;
  cost: string;
}

const CardAuto: FC<PaymentModalProps> = ({
  isOpen,
  header,
  mostRecentCard,
  onClose,
  onPaymentComplete,
  onPaymentFail,
  onStartAgain,
  onAddCard,
  purchaseExpired,
  cost,
}) => {
  const viewport = useViewport();
  const dispatch = useAppDispatch();

  const { expiryTime } = useAppSelector((state) => state.modalSlice);

  const [isAwaitingPaymentConfirmation, setIsAwaitingPaymentConfirmation] =
    useState<boolean>(false);
  const [show3dsFrame, setShow3dsFrame] = useState<boolean>(false);
  const [threeDSLink, set3DSLink] = useState<string>();
  const [showCvvModal, setShowCvvModal] = useState<boolean>(false);
  const [applePaySupported, setApplePaySupported] = useState<boolean>(false);

  const [showExceededAttempts, setShowExceededAttempts] =
    useState<boolean>(false);

  const [modalWidth, setModalWidth] = useState<number>(434);

  const setPublicKey = useStoreActions(
    (actions) => actions.authentication.setPublicKey,
  );

  const setGlobalBanner = useStoreActions(
    (actions) => actions.globalbanner.setGlobalBanner,
  );

  useEffect(() => {
    if ((window as any).ApplePaySession) {
      if (process.env.REACT_APP_APPLE_PAY_ALLOWED) {
        setApplePaySupported(false)
        /*
        const merchantIdentifier =
          process.env.REACT_APP_APPLE_PAY_MERCHANT_NAME;

        /*const applePaySupported = (
          window as any
        ).ApplePaySession.canMakePayments(merchantIdentifier);

        setApplePaySupported(false);*/
      }
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      (window as any).gtag('event', 'purchase_card', {});
    }

    if (expiryTime > 0 && expiryTime < new Date().getTime()) {
      purchaseExpired();
    } else if (isOpen) {
      dispatch(setModalType('CardAuto'));
      dispatch(setShowState(isOpen));
    }
  }, [isOpen]);

  useEffect(() => {
    restService
      .getPublicKey()
      .then((response) => {
        setPublicKey(response as PublicKey);
      })
      .catch((error) => {
        setGlobalBanner({
          title: 'Error:',
          text: error.message,
        });
      });
  }, []);

  useEffect(() => {
    (window as any).finalise3DS = function (id: string) {
      setShow3dsFrame(false);
      setIsAwaitingPaymentConfirmation(true);

      let poll: NodeJS.Timer;
      // First couple of api pokes will fail without the delay
      setTimeout(() => {
        // wiat 2 seconds

        // Poll payment endpoint
        poll = setInterval(() => {
          // every 5 seconds
          restService
            .getPayment(id)
            .then((res) => {
              if (res.status === 'confirmed') {
                clearInterval(poll);
                setIsAwaitingPaymentConfirmation(false);
                onPaymentComplete(id);
              }
              if (res.status === 'failed') {
                clearInterval(poll);
                onFailedPurchaseAttempt(res);
              }
            })
            .catch(() => {
              setIsAwaitingPaymentConfirmation(false);
              onPaymentFail('');
              clearInterval(poll);
            });
        }, 5000);
      }, 2000);
    };
  }, []);

  function onFailedPurchaseAttempt(res: any) {
    const purchaseAttempt = window.localStorage.getItem('purchase_attempt');

    if (!purchaseAttempt) {
      window.localStorage.setItem('purchase_attempt', '1');
    }

    if (Number(purchaseAttempt) >= 3) {
      setIsAwaitingPaymentConfirmation(false);
      setShowExceededAttempts(true);
      window.localStorage.removeItem('purchase_attempt');
    } else {
      const currentAttempt = window.localStorage.getItem('purchase_attempt');
      if (currentAttempt) {
        window.localStorage.setItem(
          'purchase_attempt',
          String(parseInt(currentAttempt) + 1),
        );
      }

      let source;
      try {
        source = res.source;
      } catch {
        source = '';
      }

      let error_string = '';
      error_string = checkCircleError(
        res.data.errorCode,
        res.data.verification,
        source,
      );
      onPaymentFail(error_string);
    }
  }

  function _handleApplePayEvents(appleSession: any) {
    appleSession.onvalidatemerchant = async function (event: any) {
      try {
        const merchantSession = await restService.applePayMerchantValidation(
          event.validationURL,
        );
        if (merchantSession) {
          appleSession.completeMerchantValidation(merchantSession);
        }
      } catch (error) {
        appleSession.abort();
      }
    };

    appleSession.oncancel = function (event: any) {
      event;
    };

    appleSession.onpaymentauthorized = async function (event: any) {
      const token_type = 'applepay';

      const tokens = event.payment.token;

      const tokenData = {
        version: tokens.paymentData.version,
        data: tokens.paymentData.data,
        signature: tokens.paymentData.signature,
        header: tokens.paymentData.header,
      };

      try {
        const purchaseId = window.localStorage.getItem('purchaseId');

        const paymentToken = await restService.circleAppleGoogleToken(
          token_type,
          tokenData,
          purchaseId,
        );

        const id = paymentToken.paymentid;

        let poll: NodeJS.Timer;
        // First couple of api pokes will fail without the delay
        setTimeout(() => {
          // wiat 2 seconds

          // Poll payment endpoint
          poll = setInterval(() => {
            // every 5 seconds
            restService
              .getPayment(id)
              .then((res) => {
                if (res.status === 'confirmed') {
                  clearInterval(poll);
                  appleSession.completePayment(appleSession.STATUS_SUCCESS);
                  onPaymentComplete(id);
                }
                if (res.status === 'failed') {
                  clearInterval(poll);
                  appleSession.completePayment(appleSession.STATUS_FAILURE);
                  setIsAwaitingPaymentConfirmation(false);
                  onFailedPurchaseAttempt(res);
                }
              })
              .catch((error) => {
                error;
                //console.log(error);
                appleSession.completePayment(appleSession.STATUS_FAILURE);
                setIsAwaitingPaymentConfirmation(false);
                onPaymentFail('');
                clearInterval(poll);
              });
          }, 5000);
        }, 2000);
      } catch (error) {
        appleSession.completePayment(appleSession.STATUS_FAILURE);
        //appleSession.abort()
      }
    };
  }

  function onApplePayPayment() {
    const request = {
      countryCode: 'US',
      currencyCode: 'USD',
      supportedNetworks: ['visa', 'masterCard'],
      merchantCapabilities: ['supports3DS'],
      total: {
        label: process.env.REACT_APP_APPLE_PAY_MERCHANT_NAME,
        amount: parseFloat(cost),
      },
    };
    const session = new (window as any).ApplePaySession(6, request);
    _handleApplePayEvents(session);

    session.begin();
  }

  function onCardSubmit() {
    
    const purchaseId = window.localStorage.getItem('purchaseId') || '';

    console.log('purchaseId: ', purchaseId);
    
    restService
      .payWithBalance(purchaseId)
      .then(() => {
        
          setIsAwaitingPaymentConfirmation(false);
          onPaymentComplete('123');
        
      })
      .catch(() => {
        setIsAwaitingPaymentConfirmation(false);
        onPaymentFail('');
      });

  }

  useEffect(() => {
    if (show3dsFrame) {
      setModalWidth(750);
    } else {
      setModalWidth(434);
    }
  }, [show3dsFrame]);

  async function submitHandler(encryptedCvv?: string) {
    let verification = '';

    if (mostRecentCard) {
      if (mostRecentCard.verification_type === '3ds') {
        verification = 'three_d_secure';
      } else {
        verification = mostRecentCard.verification_type
          ? mostRecentCard.verification_type
          : '';
      }
    }

    const paymentDetails: PaymentDetailsType = {
      purchaseId: window.localStorage.getItem('purchaseId'),
      source: {
        type: 'card',
        id: mostRecentCard.cardId,
      },
      verification: verification,
    };

    if (
      mostRecentCard &&
      mostRecentCard.verification_type === 'cvv' &&
      encryptedCvv
    ) {
      paymentDetails.keyId = store.getState().authentication.publicKey.keyId;
      paymentDetails.encryptedData = encryptedCvv;
    }

    restService
      .makeCardPayment(paymentDetails)
      .then((res) => {
        setIsAwaitingPaymentConfirmation(true);

        let poll: NodeJS.Timer;
        // First couple of api pokes will fail without the delay
        setTimeout(() => {
          // wiat 2 seconds

          // Poll payment endpoint
          poll = setInterval(() => {
            // every 5 seconds
            restService
              .getPayment(res.paymentid)
              .then((res) => {
                // CVV Only
                if (res.status === 'confirmed') {
                  clearInterval(poll);
                  setIsAwaitingPaymentConfirmation(false);
                  onPaymentComplete(res.paymentid);
                }

                if (res.status === 'action_required') {
                  // There will be a 3d secure link in the data element
                  setIsAwaitingPaymentConfirmation(false);

                  if (
                    mostRecentCard &&
                    mostRecentCard.verification_type === '3ds'
                  ) {
                    // Send iframe to 3d secure link
                    // 3d secure completion will redirect parent
                    set3DSLink(res.data.requiredAction.redirectUrl);
                    clearInterval(poll);
                    setShow3dsFrame(true);
                  }
                }
                if (res.status === 'failed') {
                  clearInterval(poll);
                  setShow3dsFrame(false);
                  onFailedPurchaseAttempt(res);
                }
              })
              .catch(() => {
                setShow3dsFrame(false);
                onPaymentFail('');
                clearInterval(poll);
              });
          }, 5000);
        }, 2000);
      })
      .catch((error: any) => {
        purchaseExpired();

        console.error('Error processing payment', error);
      });
  }

  function main_content() {
    return (
      <PaymentRow style={{ display: 'flex', flexDirection: 'column' }}>
        <Spacer height={25} />
        {applePaySupported ? (
          <>
            <div
              id="ckoApplePay"
              style={{ width: '357px' }}
              className={
                'apple-pay-button apple-pay-button-text-buy apple-pay-button-white-with-text'
              }
              lang="en"
              onClick={() => {
                onApplePayPayment();
              }}></div>
          </>
        ) : (
          <></>
        )}
        {mostRecentCard && mostRecentCard.cardId ? (
          <>
            {/* <Spacer height={30} /> */}
            <PayWithCardButton
              className="pay-with-card-button"
              height={45}
              borderRadius={50}
              bgColor={theme.colors.black}
              labelColor={theme.colors.white}
              borderColor={theme.colors.white}
              autoFocus={false}
              tabIndex={1001}
              label={
                <TextContent
                  text={`Pay using saved card ending ${
                    mostRecentCard ? mostRecentCard.last4 : ''
                  }`}
                  fontSize="fz16"
                  fontWeight="bold"
                  fontColor={theme.colors.white}
                />
              }
              onClick={() => {
                // Show Card Payment modal with the details from this card
                // already pre-populated
                // setShowCardPaymentModal(true);
                // onCardPayment();
                setIsAwaitingPaymentConfirmation(true);

                onCardSubmit()
                
              }}
            />
            {/*<FirstButton
              className="add-new-card-button"
              height={45}
              borderRadius={50}
              borderColor={theme.colors.white}
              labelColor={theme.colors.white}
              tabIndex={1001}
              label={
                <TextContent
                  text="Add a new card"
                  fontSize="fz16"
                  fontWeight="bold"
                  fontColor={theme.colors.white}
                />
              }
              onClick={() => {
                onAddCard(mostRecentCard.cardId);
              }}
            />*/}
          </>
        ) : (
          <>
            <FirstButton
              className="add-new-card-button"
              height={45}
              borderRadius={50}
              borderColor={theme.colors.white}
              labelColor={theme.colors.white}
              tabIndex={1001}
              label={
                <TextContent
                  text="Add a new card"
                  fontSize="fz16"
                  fontWeight="bold"
                  fontColor={theme.colors.white}
                />
              }
              onClick={() => {
                onAddCard();
              }}
            />
          </>
        )}
        {/* <RemainingBits /> */}
      </PaymentRow>
    );
  }

  function three_d_secure_window() {
    return (
      <div
        style={{
          background: 'white',
          overflow: 'hidden',
          width: viewport.width < 576 ? '400px' : '680px',
          height: viewport.width < 576 ? '450px' : '600px',
          marginLeft: viewport.width < 576 ? '-14px' : '',
          paddingLeft: viewport.width < 576 ? '8px' : '',
          transform: viewport.width < 576 ? 'scaleY(1)' : 'none',
        }}>
        {viewport.width < 576 ? (
          <iframe
            id="3ds-frame"
            style={{
              overflow: 'hidden',
              transform: 'scale(0.85) translate(-55px, -100px)',
            }}
            src={threeDSLink}
            width={480}
            height={600}></iframe>
        ) : (
          <iframe
            id="3ds-frame"
            style={{ overflow: 'hidden' }}
            src={threeDSLink}
            width={680}
            height={600}></iframe>
        )}
      </div>
    );
  }

  function loading_screen() {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: viewport.width < 576 ? '290px' : '434px',
          marginTop: '180px',
          marginLeft: viewport.width < 576 ? '40px' : '-40px',
          paddingLeft: '0px',
        }}>
        <BeatLoader color="white" loading={isAwaitingPaymentConfirmation} />
      </div>
    );
  }

  function payment_attempts_exceeded() {
    return (
      <AttemptsExceededContainer>
        <BoldText
          text="Payment Failed!"
          fontSize="fz24"
          fontColor={theme.colors.yellow}
        />
        <ExceededTextContainer>
          <Typography
            text="Sorry, your payment has failed three times. Please reselect your bits to try buying again."
            fontSize="fz16"
            lineHeight="26px"
          />
        </ExceededTextContainer>
        <Spacer height={5} />
        <FirstButton
          label="Start Again"
          fontSize={16}
          bgColor={theme.colors.yellow}
          labelColor={theme.colors.black}
          width={357}
          height={45}
          borderRadius={50}
          onClick={() => {
            setShowExceededAttempts(false);
            onStartAgain();
          }}
        />
      </AttemptsExceededContainer>
    );
  }

  function getModalContent() {
    if (show3dsFrame) {
      return three_d_secure_window();
    } else if (isAwaitingPaymentConfirmation) {
      return loading_screen();
    } else if (showExceededAttempts) {
      return payment_attempts_exceeded();
    } else {
      return main_content();
    }
  }

  function getModalTitle() {
    if (show3dsFrame) {
      return 'card verification';
    } else {
      return 'reservation';
    }
  }

  useEffect(() => {
    if (!isOpen) {
      setShow3dsFrame(false);
      setIsAwaitingPaymentConfirmation(false);
    }
  }, [show3dsFrame, isAwaitingPaymentConfirmation, isOpen]);

  return (
    <SBModal
      isOpen={isOpen}
      width={modalWidth + 'px'}
      mobileWidth="100%"
      height="662px"
      top={getTopPosition('10%')}
      mobileTop="2%"
      withProceedingText={!(isAwaitingPaymentConfirmation || show3dsFrame)}
      withSettings={false}
      withFeeText={!(isAwaitingPaymentConfirmation || show3dsFrame)}
      feeBottomPos="55px"
      content={
        <>
          <CloseButton
            onClick={() => {
              setShow3dsFrame(false);
              setIsAwaitingPaymentConfirmation(false);
              setShowExceededAttempts(false);
              onClose();
            }}>
            X
          </CloseButton>

          <BoldText
            text={getModalTitle()}
            fontWeight="bold"
            fontSize={viewport.width >= 576 ? 'fz48' : 'fz30'}
          />
          <Spacer height={20} />
          {!(isAwaitingPaymentConfirmation || show3dsFrame) ? (
            <>
              {header}
              <Spacer height={55} />
            </>
          ) : (
            <></>
          )}

          {getModalContent()}

          <SBModal
            isOpen={showCvvModal}
            width="434px"
            height="590px"
            mobileHeight="550px"
            top={getTopPosition('10%')}
            withProceedingText={true}
            withFeeText={true}
            withSettings={false}
            content={
              <>
                <BoldText
                  text={getModalTitle()}
                  fontWeight="bold"
                  fontSize={viewport.width >= 576 ? 'fz48' : 'fz30'}
                />
                <Spacer height={20} />
                {header}
                <CVVModal
                  onClose={() => {
                    setShowCvvModal(false);
                  }}
                  purchaseExpired={() => {
                    purchaseExpired();
                  }}
                  last4={mostRecentCard ? mostRecentCard.last4 : ''}
                  onEncryptedCvv={(encryptedCvv: string) => {
                    submitHandler(encryptedCvv);
                    setIsAwaitingPaymentConfirmation(true);
                    setShowCvvModal(false);
                  }}
                />
              </>
            }
          />
        </>
      }
    />
  );
};

const TextContent = styled(Typography)<{
  withUnderline?: boolean;
  withCursorPointer?: boolean;
  gap?: number;
}>`
  letter-spacing: -0.03em;
  flex-shrink: 0;
  ${(props) => props.withUnderline && 'text-decoration: underline'};
  ${(props) => props.withCursorPointer && 'cursor: pointer;'};
`;

const FirstButton = styled(Button)`
  padding: 0 60px;
  min-width: 300px;
  width: 350px;
  margin-left: -5px;
  border-width: 1px;

  @media (min-width: 576px) {
    min-width: 350px;
  }
`;

const PayWithCardButton = styled(Button)`
  padding: 0 60px;
  min-width: 300px;
  margin-left: -5px;
  border-width: 1px;

  @media (min-width: 576px) {
    min-width: 350px;
  }
`;

const PaymentRow = styled.div`
  gap: 25px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const CloseButton = styled.div`
  color: white;
  position: absolute;
  top: 28px;
  right: 25px;
  font-size: 20px;
  cursor: pointer;
`;

const BoldText = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  letter-spacing: -0.03em;
`;

const AttemptsExceededContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 15px;
  margin-top: -25px;
`;

const ExceededTextContainer = styled.div`
  padding: 0 40px;
  margin-top: -5px;
`;

export default memo(CardAuto);
