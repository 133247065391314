import React, { useState, FC } from 'react';
import styled from 'styled-components';
import { useViewport } from 'use-viewport';

import Button from 'common/components/Button/Button';
import Typography from 'common/components/Typography/Typography';
import Edit from 'common/icons/Edit.icon';
import theme from 'theme/theme';

import ChangePasswordModal from '../ChangePasswordModal/ChangePasswordModal';
import SBModal from 'common/components/Modal/SBModal';
import ResetPasswordToast from '../ResetPasswordToast/ResetPasswordModal';

interface ChangePasswordProps {
  email: string;
  onPasswordReset: () => void;
}

const ChangePassword: FC<ChangePasswordProps> = ({
  email,
  onPasswordReset,
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showResetModal, setShowResetModal] = useState<boolean>(false);

  const viewport = useViewport();

  const onClick = () => {
    setShowModal(true);
  };

  return (
    <Container>
      <PasswordButtonContainer>
        <Typography
          fontSize={viewport.width >= 576 ? 'fz18' : 'fz12'}
          text="●●●●●●●●●●"
          fontColor={theme.colors.gray}
        />
        <Button
          className='change-password-button'
          label={
            viewport.width >= 576 ? (
              <Edit width={20} height={20} />
            ) : (
              <Edit width={14} height={14} />
            )
          }
          onClick={onClick}
        />
      </PasswordButtonContainer>
      {/* Change Password Modal */}
      <SBModal
        isOpen={showModal}
        height={viewport.width >= 576 ? '556px' : '491px'}
        top="20%"
        content={
          <ChangePasswordModal
            onChange={() => {
              setShowModal(false);
            }}
            onTriggerReset={() => {
              setShowModal(false);
              setShowResetModal(true);
            }}
          />
        }
      />
      {/* Reset Password Modal */}
      <SBModal
        isOpen={showResetModal}
        height={viewport.width >= 576 ? '373px' : '339px'}
        top="25%"
        content={
          <ResetPasswordToast
            email={email}
            onChange={() => {
              setShowResetModal(false);
            }}
            onResetPassword={() => {
              onPasswordReset();
            }}
          />
        }
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 60px;
  position: relative;
`;

const PasswordButtonContainer = styled.div`
  height: 60px;
  width: 100%;
  padding: 0 20px 0 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default ChangePassword;
