import styled from 'styled-components';

import Typography from 'common/components/Typography/Typography';
import theme from 'theme/theme';
import { xs } from 'utils/constants';

export const ModalContainer = styled.div<{
  padding?: boolean | null ;
}>`
  width: 100%;

  ${(props) => (props.padding ? 'padding-left: 30px;' : '')}
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const Title = styled(Typography)<{
  bottomMargin?: string | null ;
}>`
  font-family: 'HKGrotesk-Black';
  letter-spacing: -0.03em;
  margin-bottom: ${(props) => (props.bottomMargin ? props.bottomMargin : '30px')};
`;
export const TitleCenter = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  letter-spacing: -0.03em;
  margin-top: 15px;
  text-align: center;
`;

export const CloseButton = styled.div`
  color: ${theme.colors.white};
  position: absolute;
  top: 28px;
  right: 25px;
  font-size: 20px;
  cursor: pointer;
`;

export const ModalContentContainer = styled.div<{
  flexDir?: string;
  alignItems?: string;
  justifyContent?: string;
  padding?: string;
}>`
  display: flex;
  flex-direction: ${(props) => (props.flexDir ? props.flexDir : 'column')};
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'center')};
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : 'center'};
  width: 100%;

  padding: ${(props) => (props.padding ? props.padding : '0')};
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: inherit;
`;

export const RowContainer = styled.div<{
  marginBottom?: string;
  direction?: string;
  gap?: string;
}>`
  display: flex;
  flex-direction: ${(props) => (props.direction ? props.direction : 'row')};
  gap: ${(props) => (props.gap ? props.gap : 0)};

  position: relative;

  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : '25px'};
`;

export const IconContainer = styled.div<{ left?: string }>`
  width: 30px;

  position: absolute;
  left: ${(props) => (props.left ? props.left : '-45px')};

  overflow: visible;
`;

export const Text = styled(Typography)`
  font-size: 18px;

  @media (min-width: ${xs}px) {
    font-size: 20px;
  }
`;

export const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;

  margin-top: 93px;
`;
