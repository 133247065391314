import axios from "axios";
import store from "store/store";
import client from "../graphql/client"
import { REFRESH_TOKEN } from "modules/auth/graphql/Mutations.graphql";
import jwtDecode from "jwt-decode";


const instance = axios.create({
    withCredentials: false,
    baseURL: process.env.REACT_APP_REST_URL,
    headers: {
        "Content-Type": "application/json",
    },
});

export function handleErrors(error: any) {
  if (axios.isAxiosError(error)) {
    console.error('error message: ', error.message);
    throw new Error(error.message);
  } else {
    console.error('unexpected error: ', error);
    throw new Error('An unexpected error occurred');
  }
}

instance.interceptors.request.use(
    async (config) => {
        const state = store.getState();
        const expiryTime = state.authentication.expiryTime;
        const currentTime = Math.floor((Date.now()) / 1000); // in seconds

        const expiryCondition = Number(expiryTime) < currentTime && expiryTime;

        const token = expiryCondition ? await refreshTokens() : store.getState().authentication.accessToken;

        if (token) {
            config.headers["Authorization"] = 'Bearer ' + token;
        }
        
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

function refreshTokens(): Promise<string> {
    return new Promise(async(resolve) => {
        const { data } = await client.mutate({
            mutation: REFRESH_TOKEN,
            variables: { refreshToken: store.getState().authentication.refreshToken }
        });
    
        if (data) {
            store
                .getActions()
                .authentication.setAccessToken(data.refreshToken.auth.accessToken);
            store
                .getActions()
                .authentication.setRefreshToken(data.refreshToken.auth.refreshToken);
    
            const decoded = jwtDecode(
                data.refreshToken.auth.accessToken,
            );

            store.getActions().authentication.setExpiryTime((decoded as any).exp);
            
            resolve(data.refreshToken.auth.accessToken);
        }

        resolve;
    });
}

export default instance;
