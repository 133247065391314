import { action } from 'easy-peasy';

import { AuthModel } from 'store/models/authModel/authModel.types';

import jwtDecode from 'jwt-decode';

export const authentication: AuthModel = {
  isAuthenticated: false,
  isEmailConfirmed: false,
  redirectToGiveaway: false,
  kyc: false,
  fcaStep: null,
  personalRisk: false,
  location: null,
  accessToken: null,
  refreshToken: null,
  emailAddress: null,
  expiryTime: null,
  isArtist: false,
  slug: null,
  publicKey: { keyId: '', publicKey: '' },
  setAccessToken: action((state, payload) => {
    state.accessToken = payload;
    state.isAuthenticated = true;

    const decoded = jwtDecode(payload);

    if ((decoded as any).role === 'app_artist') {
      state.isArtist = true;
    }
  }),
  setRefreshToken: action((state, payload) => {
    state.refreshToken = payload;
    state.isAuthenticated = true;
  }),
  setExpiryTime: action((state, payload) => {
    state.expiryTime = payload;
    state.isAuthenticated = true;
  }),
  setPublicKey: action((state, payload) => {
    state.publicKey = payload;
  }),
  setEmailConfirm: action((state, payload) => {
    state.isEmailConfirmed = payload;
  }),
  setEmailAddress: action((state, payload) => {
    state.emailAddress = payload;
  }),
  setSlug: action((state, payload) => {
    state.slug = payload;
  }),
  setRedirectToGiveaway: action((state, payload) => {
    state.redirectToGiveaway = payload;
  }),
  setLocation: action((state, payload) => {
    state.location = payload;
  }),
  setFcaStep: action((state, payload) => {
    state.fcaStep = payload;
  }),
  setOnboarding: action((state, payload) => {

    state.fcaStep = payload

    let kyc = false;
    if (payload === 'required' || payload === 'retry' || payload === null || payload === 'expired' || payload === 'in_flow') {
      if ((state.location === 'GB' || state.location === 'NI' || state.location === 'UK')) {
        kyc = true
      } else {
        kyc = false
      }
    } else {
      kyc = false
    }

    //
    state.kyc = kyc;
  }),
  setLogout: action((state) => {
    state.accessToken = null;
    state.emailAddress = null;
    state.refreshToken = null;
    state.expiryTime = null;
    state.isAuthenticated = false;
    state.isArtist = false;
    state.slug = null;
    state.kyc = false;
    state.fcaStep = null;
    state.redirectToGiveaway = false;
  }),
};
