import { gql } from '@apollo/client';

export const AUTHENTICATE_MUTATION = gql`
  mutation AUTHENTICATE($username: Username!, $password: String!) {
    authenticate(input: { username: $username, password: $password }) {
      auth {
        accessToken
        refreshToken
        isEmailConfirmed
        redirectToGiveaway
        redirectAfterAuth
        kycStatus
        fcaStep
      }
    }
  }
`;

export const AUTHENTICATE_EMAIL_MUTATION = gql`
  mutation AUTHENTICATE($email: Email!, $password: String!) {
    authenticateEmail(input: { email: $email, password: $password }) {
      auth {
        accessToken
        refreshToken
        isEmailConfirmed
        redirectToGiveaway
        kycStatus
        fcaStep
      }
    }
  }
`;

// $artist: UUID
// $song: UUID
// artist: $artist
// song: $song

export const REGISTER_USER = gql`
  mutation REGISTER_USER(
    $username: String!
    $email: Email!
    $password: PasswordType!
    $country: Int!
    $county: Int
    $waitlist_token_id: UUID
    $artist: UUID
    $song: UUID
    $utm: JSON
  ) {
    registerUser(
      input: {
        _username: $username
        _email: $email
        _password: $password
        _country: $country
        _county: $county
        _waitlistTokenId: $waitlist_token_id
        _artist: $artist
        _song: $song
        _utm: $utm
      }
    ) {
      results {
        tokenId
        auser {
          id
        }
      }
      clientMutationId
    }
  }
`;

export const REGISTER_USER_WAITLIST = gql`
  mutation REGISTER_USER_WAITLIST(
    $username: String!
    $email: Email!
    $password: PasswordType!
    $country: Int!
    $tokenId: UUID!
  ) {
    registerUserWaitlist(
      input: {
        username: $username
        _email: $email
        password: $password
        country: $country
        tokenId: $tokenId
      }
    ) {
      user {
        id
      }
      clientMutationId
    }
  }
`;

export const REGISTER_USER_WAITLIST_USA = gql`
  mutation REGISTER_USER_WAITLIST_USA(
    $username: String!
    $email: Email!
    $password: PasswordType!
    $country: Int!
    $county: Int!
    $tokenId: UUID!
  ) {
    registerUserUsaWaitlist(
      input: {
        username: $username
        _email: $email
        password: $password
        country: $country
        county: $county
        tokenId: $tokenId
      }
    ) {
      user {
        id
      }
      clientMutationId
    }
  }
`;

export const CONFIRM_EMAIL = gql`
  mutation ConfirmationEmail($token: String!) {
    userConfirmationEmail(input: { tokenValue: $token }) {
      auth {
        accessToken
        isEmailConfirmed
        isInternal
        redirectAfterAuth
        redirectToGiveaway
        kycStatus
        fcaStep
      }
    }
  }
`;

// Verification Email / Resend Verification Email
export const VERIFICATION_EMAIL = gql`
  mutation VerificationEmail($email: Email!) {
    userSendConfirmationEmail(input: { emailValue: $email }) {
      clientMutationId
    }
  }
`;

export const RESEND_VERIFICATION_EMAIL = gql`
  mutation ResendVerificationEmail($expiredTokenId: UUID, $email: Email) {
    userResendConfirmationEmail(input: { expiredTokenId: $expiredTokenId, emailAddress: $email }) {
      responseType {
        message
        status
      }
    }
  }
`;

export const VERIFICATION_EMAIL_CONFIRM = gql`
  mutation VerifyEmail($tokenValue: String!) {
    userConfirmationEmail(input: { tokenValue: $tokenValue }) {
      clientMutationId
    }
  }
`;

export const CHANGE_EMAIL = gql`
  mutation ChangeEmail($userId: UUID!, $email: Email!) {
    userChangeEmailRequest(input: { userId: $userId, newEmail: $email }) {
      clientMutationId
    }
  }
`;

export const CHANGE_EMAIL_CONFIRM = gql`
  mutation ChangeEmailConfirm($tokenValue: String!) {
    userChangeEmailRequestConfirmation(input: { tokenValue: $tokenValue }) {
      clientMutationId
    }
  }
`;

export const CHANGE_USERNAME = gql`
  mutation ChangeUsername($userId: UUID!, $UsernameDepricated: String!) {
    userUpdateUsername(input: { userid: $userId, newUsername: $newUsername }) {
      clientMutationId
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($email: Email!) {
    userSendResetPassword(input: { emailValue: $email }) {
      responseType {
        status
        message
      }
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($tokenValue: String!, $password: PasswordType!) {
    userUpdatePasswordByToken(
      input: { tokenValue: $tokenValue, newPassword: $password }
    ) {
      clientMutationId
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation RefreshToken($refreshToken: String!) {
    refreshToken(input: { refreshToken: $refreshToken }) {
      auth {
        accessToken
        refreshToken
      }
    }
  }
`;

export const CHECK_WAITLIST_TOKEN = gql`
  mutation CHECK_WAITLIST_TOKEN($tokenId: UUID!, $emailAddress: Email!) {
    isWaitlistTokenValid(
      input: { emailAddress: $emailAddress, tokenId: $tokenId }
    ) {
      boolean
    }
  }
`;
